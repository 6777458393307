@tailwind base;
@tailwind components;
@tailwind utilities;




@font-face {
  font-family: 'MarkelSans';
  src: url('https://unbouncemarkel.s3-eu-west-2.amazonaws.com/rebrand/MarkelSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}



@font-face {
  font-family: 'MarkelSansMedium';
  src: url('https://unbouncemarkel.s3-eu-west-2.amazonaws.com/rebrand/MarkelSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s; /* Adjust duration as needed */
  animation-fill-mode: forwards; /* Keeps the div hidden after animation */
}


@font-face {
  font-family: 'MarkelSansBold';
  src: url('https://unbouncemarkel.s3-eu-west-2.amazonaws.com/rebrand/MarkelSans-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
